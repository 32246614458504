// ==============================
//* MINI CART
// Overrides app_storefront_base
//? This file manages the mini cart custom behavior.
// ==============================

var cart = require("../cart/cart");

var updateMiniCart = true;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? "alert-success" : "alert-danger";

    if ($(".add-to-wishlist-messages").length === 0) {
        if ($(window).width() > 992) { // NOSONAR
            $("body").append("<div class=\"add-to-wishlist-messages \"></div>");
        } else {
            $("body").append("<div class=\"add-to-wishlist-messages \"></div>");
        }
    }

    $(".add-to-wishlist-messages")
        .append("<div class=\"add-to-wishlist-alert text-center " + status + "\">" + data.msg + "</div>");

    setTimeout(function () {
        $(".add-to-wishlist-messages").remove();
    }, 1500);
    var $targetElement = $("a[data-pid=\"" + data.pid + "\"]").closest(".product-info").find(".remove-product");
    var itemToMove = {
        actionUrl: $targetElement.data("action"),
        productID: $targetElement.data("pid"),
        productName: $targetElement.data("name"),
        uuid: $targetElement.data("uuid")
    };
    $("body").trigger("afterRemoveFromCart", itemToMove);
    setTimeout(function () {
        $(".cart.cart-page #removeProductModal").modal();
    }, 2000);
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $("body").on("click", ".product-move .move", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        var pid = $(this).data("pid");
        var optionId = $(this).closest(".product-info").find(".lineItem-options-values").data("option-id");
        var optionVal = $(this).closest(".product-info").find(".lineItem-options-values").data("value-id");
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

module.exports = {
    moveToWishlist: moveToWishlist
};

module.exports = function () {
    cart();
    var url = $(".minicart").data("action-url");

    const updateMiniCartView = () => {
        $.get(url, function (data) {
            $(".minicart .popover").empty();
            $(".minicart .popover").append(data);
            updateMiniCart = false;
            $.spinner().stop();
        });
    };

    $(".minicart").on("count:update", function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $(".minicart .minicart-quantity").text(count.quantityTotal);
            $(".minicart .minicart-link").attr({
                "aria-label": count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $(".minicart").on("mouseenter focusin touchstart", function () {
        if (window.matchMedia("(max-width: 992px)").matches) {
            return;
        }
        var count = parseInt($(".minicart .minicart-quantity").text(), 10);

        if (count !== 0 && $(".minicart .popover.show").length === 0) {
            if (!updateMiniCart) {
                $(".minicart .popover").addClass("show");
                return;
            }

            $(".minicart .popover").spinner().start();
            $(".minicart .popover").addClass("show");
            updateMiniCartView();
        } else if (!updateMiniCart) {
            $(".minicart .popover").addClass("show");
        }
    });

    $("body").on("touchstart click", function (e) {
        if ($(".minicart").has(e.target).length <= 0) {
            $(".minicart .popover").removeClass("show");
        }
    });

    $(".minicart").on("mouseleave focusout", function (event) {
        if ((event.type === "focusout" && $(".minicart").has(event.target).length > 0)
            || (event.type === "mouseleave" && $(event.target).is(".minicart .quantity"))
            || $("body").hasClass("modal-open")) {
            event.stopPropagation();
            return;
        }
        $(".minicart .popover").removeClass("show");
    });

    $("body").on("change", ".minicart .quantity", function () {
        if ($(this).parents(".bonus-product-line-item").length && $(".cart-page").length) {
            window.location.reload();
        }
    });

    $("body").on("product:afterAddToCart", function () {
        updateMiniCart = true;
    });

    $("body").on("cart:update", function () {
        updateMiniCart = true;
    });

    /**
     * Update the cart view when an element is removed from the minicart
     */
    $(function () {
        const elemToObserve = $(".minicart-quantity")[0];

        const mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if ($(".minicart .popover").hasClass("show")
                    && mutation.removedNodes.length
                    && Number(mutation.removedNodes[0].nodeValue) > 1) {
                    updateMiniCartView();
                }
            });
        });

        const mutationObserverConfig = {
            characterData: true,
            attributes: false,
            childList: true,
            subtree: false
        };

        if (elemToObserve) {
            mutationObserver.observe(elemToObserve, mutationObserverConfig);
        }
    });
};
