// ==============================
//* SHOW FOOTER LIST
//
//? This file manages the footer's list toggle behavior.
// ==============================

module.exports = function () {
    if (window.matchMedia("(max-width: 721px)").matches) {
        $(".js-showList").on("click", function () {
            var item = $(this).find(".js-panel");
            $(this).toggleClass("show").find(item).slideToggle();
        });
    }
};
