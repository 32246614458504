// ==============================
//* FAKE SELECT
//
//? This file add script to manage some custom select.
// ==============================

// setup select
const setupFakeSelect = {
    selectors: {
        select: ".fake-select",
        selectContent: ".fake-select__content",
        button: ".fake-select__content button",
        buttonSpanSelected: ".fake-select__content button span.selected",
        text: ".fake-select__selected"
    },
    class: {
        active: "active",
        selected: "selected",
        one: "only-one"
    },

    init() {
        let $select = $(this.selectors.select);

        $select.each((index) => {
            let $selectUsed = $($select[index]);
            let $selectContent = $selectUsed.find(this.selectors.selectContent);
            let $buttons = $selectUsed.find(this.selectors.button);
            let $text = $selectUsed.find(this.selectors.text);
            let $buttonSpanSelected = $selectUsed.find(this.selectors.buttonSpanSelected);

            if ($buttons.length <= 1) {
                $selectUsed.addClass(this.class.one);
                $text.html($buttons.html());
            } else {
                // Add active color in fake select / if is variationGroup (color)
                if ($buttonSpanSelected.length > 0) {
                    let $buttonSelected = $($buttonSpanSelected)[0].closest("button");
                    $text.html($($buttonSelected)[0].innerHTML);
                    $buttons.removeClass(this.class.selected);
                    $($buttonSpanSelected).addClass(this.class.selected);
                } else {
                    $text.html($($buttons[0])[0].innerHTML);
                    $buttons.removeClass(this.class.selected);
                    $($buttons[0]).addClass(this.class.selected);
                }

                $selectUsed.on("click", (e) => {
                    $(e.currentTarget).find(this.selectors.selectContent).toggleClass(this.class.active);
                });

                $buttons.on("click", (e) => {
                    if (!$(e.currentTarget).hasClass(this.class.selected)) {
                        $text.html($(e.currentTarget).html());
                        $buttons.removeClass(this.class.selected);
                        $(e.currentTarget).addClass(this.class.selected);
                    }
                });

                document.addEventListener("click", (e) => {
                    if ($selectUsed.has(e.target).length <= 0) {
                        $selectContent.removeClass(this.class.active);
                    }
                });
            }
        });
    }
};
setupFakeSelect.init();
