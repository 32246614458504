// ==============================
//* TOP BANNER
//
//? This file manages the top banner behavior.
// ==============================

$(() => {
    const headerTopBanner = ".header-top-banner";
    const headerTopBannerContainer = ".header-top-banner__container";
    const headerTopBannerMessage = ".header-top-banner__message";
    const animationSpeed = ($(headerTopBanner).data("animation-speed") !== undefined) ? $(headerTopBanner).data("animation-speed") : 10000;

    // First, check if the top banner has an enabled message
    if ($(headerTopBannerMessage).children().length) {
        if (window.matchMedia("(max-width: 992px)").matches) {
            // Clone the element to init the slider structure
            $(headerTopBannerMessage).clone().appendTo(headerTopBannerContainer);

            // Init the slider
            $(headerTopBannerContainer).slick({
                speed: animationSpeed,
                autoplay: true,
                infinite: true,
                autoplaySpeed: 0,
                cssEase: "linear",
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                arrows: false
            });
        }
    } else {
        $(headerTopBanner).hide();
    }
});
