// ==============================
//* COOKIES BUTTON
//
//? This file add script to manage cookies button placement.
// ==============================

// setup select
const cookiesBtn = {
    selectors: {
        container: "#footer .container-xl",
        button: "#ot-sdk-btn"
    },

    init() {
        let $windowWidth = $(window).width();
        let $button = $(this.selectors.button);
        let $container = $(this.selectors.container);
        let $containerWidth = $container.width();
        let $posBtn = ($windowWidth - $containerWidth) / 2;

        $button.css("left", $posBtn);

        $(window).resize(function () {
            $windowWidth = $(window).width();
            $containerWidth = $container.width();
            $posBtn = ($windowWidth - $containerWidth) / 2;

            if ($posBtn <= 15) {
                $posBtn = 15;
            }

            $button.css("left", $posBtn);

        });
    }
};
cookiesBtn.init();
