const Lazyload = require("vanilla-lazyload");

/**
 * Init lazyLoad
 */
function init() {
    const lazyLoad = new Lazyload({
        unobserve_entered: true,
        elements_selector: ".js-lazy",
        class_loaded: "js-lazy-loaded",
        class_error: "js-lazy-error",
        threshold: 300,
        callback_error: (image) => {
            if (image.dataset.srcFallback) {
                image.src = image.dataset.srcFallback;
            }
        }
    });

    $(document).ajaxStop(() => {
        setTimeout(() => {
            lazyLoad.update();
        }, 10);
    }).on("lazy-load:update", () => {
        lazyLoad.update();
    });
}

module.exports = {
    init: init
};
