// ==============================
//* SIMPLE SLIDER
//
//? This file gathers content assets simple sliders scripts.
// ==============================

$(() => {
    const sectionsSliders = ".simple-slider";

    for (let i = 0; i < $(sectionsSliders).length; i++) {
        const sectionSlider = $(sectionsSliders)[i];
        // const sliderWrapper = $(sectionSlider).find('.slider-wrapper');
        const slider = $(sectionSlider).find(".slider");
        const sliderChildren = $(slider).find("> div");

        if (sliderChildren.length > 1) {
            // Init slider
            $(slider).slick({
                infinite: true,
                cssEase: "linear",
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            infinite: true,
                            cssEase: "linear",
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            infinite: true,
                            cssEase: "linear",
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true
                        }
                    }
                ]
            });

            // Set disabled class on prev button on page load
            const slickPrev = $(slider).find(".slick-prev");
            const slickNext = $(slider).find(".slick-next");

            if (slickPrev.length > 0) $(slickPrev).addClass("disabled");
            $(slickNext).on("click", () => {
                $(slickPrev).removeClass("disabled");
            });
        }
    }
});
