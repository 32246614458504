// ==============================
//* HEADER
//
//? This file add script for header comportment.
// ==============================

// setup minicart
const setupMinicart = {
    selectors: {
        link: ".minicart-link",
        quantity: ".minicart-quantity"
    },
    class: {
        none: "d-none"
    },

    init() {
        let $minicart = $(this.selectors.link);
        let nbItems = $minicart.find(this.selectors.quantity);

        this.checkQuantity(nbItems);

        $(nbItems).on("DOMSubtreeModified", () => {
            this.checkQuantity(nbItems);
        });
    },
    /**
    * event to check the quantity of product on the cart
    * @param {Object} nbItems The first number.
    */
    checkQuantity(nbItems) {
        if (parseInt(nbItems.text(), 10) > 0) {
            $(nbItems).removeClass(this.class.none);
        } else {
            $(nbItems).addClass(this.class.none);
        }
    }
};
setupMinicart.init();

// setup searchbar
const setupSearchBar = {
    selectors: {
        search: ".js-search",
        searchToggle: ".js-toggle-search",
        searchField: ".search-field",
        menuToggle: ".navbar-toggler",
        mobileMenu: ".menu-toggleable-left",
        modalBg: ".modal-background",
        mainMenu: ".main-menu",
        navbarRight: ".navbar-header__right",
        dropdownMenu: ".dropdown-menu"
    },
    class: {
        active: "active",
        noScroll: "no-scroll",
        opacified: "opacified"
    },

    init() {
        let $search = $(this.selectors.search);
        let $searchToggle = $(this.selectors.searchToggle);
        let $searchField = $(this.selectors.searchField);
        let $menuToggle = $(this.selectors.menuToggle);
        let $mobileMenu = $(this.selectors.mobileMenu);
        let $modal = $(this.selectors.modalBg);
        let $menu = $(this.selectors.mainMenu);
        let $navbarRight = $(this.selectors.navbarRight);

        /**
         * Toggle the search and its according classes
         */
        const toggleSearch = (fakeClick = false) => {
            $search.toggleClass(this.class.active);
            $search.fadeToggle("fast");
            $navbarRight.toggleClass(this.class.opacified);

            if ($search.hasClass(this.class.active) && !fakeClick) {
                $searchField.focus();
            } else {
                $searchField.blur();
            }

            if ($(window).width() <= 992) {
                $("body").toggleClass(this.class.noScroll);
            }
        };

        // Mobile behaviors
        $searchToggle.on("click", (e) => {
            e.preventDefault();
            let fakeClick = false;

            if (e.originalEvent === undefined) {
                fakeClick = true;
            }

            toggleSearch(fakeClick);

            if ($mobileMenu.hasClass("in")) {
                $mobileMenu.removeClass("in");
                $modal.hide();
                $menu.attr("aria-hidden", "true");
            }
        });

        $menuToggle.on("click", () => {
            if ($search.hasClass(this.class.active)) {
                toggleSearch(false);
            }
        });
    }
};
setupSearchBar.init();

// get topcat name to make a menu breadcrumb
const topCatName = {
    selectors: {
        topcat: ".top-category",
        items: ".dropdown-link.dropdown-toggle"
    },

    init() {
        let $items = $(this.selectors.items);

        $items.on("click", () => {
            let $topCat = $(this.selectors.topcat);
            let $catTitle = $($topCat[$topCat.length - 1]);
            let $catTitleLink = $catTitle[0].attributes["data-href"].value;
            let $catPres = $($topCat[$topCat.length - 2]);

            $catTitle.html("<a href=\"" + $catTitleLink + "\">" + $catPres.text() + " / " + $catTitle.text() + "</a>");
        });
    }
};
topCatName.init();

// enhancement the main categories
const catMain = {
    selectors: {
        links: ".dropdown-item"
    },

    init() {
        let $links = $(this.selectors.links);
        $links.each(function (index) {
            let $element = $(this).find(".dropdown-link");
            if ($element.length > 0) {
                if (($element.attr("id").indexOf("-brands") >= 0 && $element.attr("id").indexOf("-brands-") < 0) || $element.attr("id").indexOf("-discovery") >= 0 && $element.attr("id").indexOf("-discovery-") < 0 || $element.attr("id").indexOf("products") >= 0) {
                    $($links[index]).addClass("main-category");
                    $($links[index]).find("> a").addClass("js-main-category");
                }
            }
        });
    }
};
catMain.init();
