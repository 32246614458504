/* eslint-disable no-param-reassign */
const helpers = {
    appendToUrl(url, params) {
        let newUrl = url;

        newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
        return newUrl;
    },

    appendParamToURL(url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        const separator = url.indexOf('?') !== -1 ? '&' : '?';

        value = decodeURIComponent(value);

        return url + separator + name + '=' + encodeURIComponent(value);
    },
    appendParamsToURL(url, params) {
        return Object.entries(params).reduce((acc, [name, value]) => {
            return helpers.appendParamToURL(acc, name, value);
        }, url);
    },

    removeParamFromURL(url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        let hash = null;
        let params = null;
        const domain = url.split('?')[0];
        let paramUrl = url.split('?')[1];
        const newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (let i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }

        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    updateURLParam(url, name, value) {
        url = this.removeParamFromURL(url, name);
        url = this.appendParamToURL(url, name, value);

        return url;
    },

    getQueryStringParams(qs) {
        if (!qs || qs.length === 0) {
            return {};
        }
        const params = {};
        // Use the string::replace method to iterate over each
        // name-value pair in the string.
        qs.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
            params[$1] = decodeURIComponent($3).replace(/\+/g, ' ');
        });
        return params;
    },

    updateURLParams(url, params) {
        let updateUrl = url;
        for (let urlParam in params) { // eslint-disable-line
            updateUrl = this.updateURLParam(updateUrl, urlParam, params[urlParam]);
        }
        return updateUrl;
    },

    getParameterValueFromUrl(parameterName, url) {
        const currentQueryString = url || window.location.search;
        const currentQueryStringParams = this.getQueryStringParams(currentQueryString);

        return currentQueryStringParams[parameterName];
    }
};


module.exports = helpers;
